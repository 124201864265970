import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20221101-Rate-Sheet.pdf";
const date = "01.11.2022";

const Article = () => (
    <NewsArticleLayout href={href} date={date} isNew={isNew}>
            <h1 className="page-title">Changes to our mortgage products</h1><br />
            <p>
              With effect from Tuesday 1 November 2022 we are changing our Residential Standard Variable Rate from 5.04% to
              5.54%.
            </p>
            <p>
              Any customers who are currently on our Residential Standard Variable Rate will be notified of changes to their
              payments in writing.
            </p>
            <p>There are no changes to our Buy to Let Standard Variable Rate at this time. </p>
            <p>There are no changes to any of our other interest rates or to fixed rate end dates at this time.</p>
            <p>An updated mortgage rate sheet will be published on Tuesday 1 November to reflect these changes.</p>

    </NewsArticleLayout>
);

export default Article;
